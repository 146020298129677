import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemText,
  Button,
  useMediaQuery,
  useTheme,
  Box,
  Container,
  ListItemButton,
  alpha,
  lighten
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { LoginButton } from 'src/content/pages/Status/Login';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import HeaderUserbox from '../SidebarLayout/Header/Userbox';
import HeaderMenu from './Menu';
import HeaderWrapper from '../Styles/Header/Header';

function HomeHeader() {
  const theme = useTheme();
  const login = useSelector((state: RootState) => state.login);
  const user = useSelector((state: RootState) => state.user.user);
  const loggedIn =
    login.loggedIn && user?._id !== undefined && user?._id !== '';
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const smoothScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setDrawerOpen(false)}
    >
      <HeaderMenu loggedIn={loggedIn} drawer />
    </Box>
  );

  return (
    <>
      <HeaderWrapper position="fixed">
        <Toolbar>
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <IconButton
              onClick={() => smoothScrollTo('home')}
              sx={{ marginRight: theme.spacing(2) }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/images/logo/icon-256x256.png`}
                alt="Logo"
                style={{ height: '48px' }}
              />
            </IconButton>
            {!isSmallScreen && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexGrow: 1
                }}
              >
                <HeaderMenu loggedIn={loggedIn} />
                {loggedIn ? <HeaderUserbox /> : <LoginButton />}
              </Box>
            )}
            {isSmallScreen && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexGrow: 1
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  sx={{ mr: 2, ...(isLargeScreen && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
                {loggedIn ? <HeaderUserbox /> : <LoginButton />}
              </Box>
            )}
          </Container>
        </Toolbar>
      </HeaderWrapper>

      {/* Drawer for medium and small screens */}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          '& .MuiDrawer-paper': {
            width: 250,
            boxSizing: 'border-box'
          }
        }}
      >
        {drawerContent}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
          paddingTop: theme.spacing(8),
          paddingLeft:
            isSmallScreen && drawerOpen ? theme.spacing(30) : theme.spacing(3)
        }}
      ></Box>
    </>
  );
}

export default HomeHeader;
