import { alpha, lighten, darken } from '@mui/material/styles';

function hexToRgb(hex) {
  const cleanHex = hex.replace('#', '');
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);
  return { r, g, b };
}

// Calculate luminance: 0 (darkest) to 1 (lightest)
function getRelativeLuminance(hex) {
  const { r, g, b } = hexToRgb(hex);
  // sRGB => linear
  const toLinear = (val) => {
    const v = val / 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  };
  const R = toLinear(r);
  const G = toLinear(g);
  const B = toLinear(b);

  // Rec. 709 weighting
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

/**
 * Decide if a color is "dark" by checking if its luminance is below ~0.5
 * Tweak threshold as you see fit.
 */
function isDarkColor(hex) {
  const lum = getRelativeLuminance(hex);
  return lum < 0.3;
}

/**
 * Creates a linear gradient string for CSS.
 *
 * @param {number|string} angle - e.g. 135 (deg)
 * @param  {...string} colors - color stops in order
 * @returns {string} e.g. 'linear-gradient(135deg, #ccc 0%, #fff 100%)'
 */
function createLinearGradient(angle, ...colors) {
  // Evenly spread color stops between 0% and 100%
  const step = 100 / (colors.length - 1);

  const colorStops = colors.map((clr, idx) => {
    return `${clr} ${Math.round(step * idx)}%`;
  });

  return `linear-gradient(${angle}deg, ${colorStops.join(', ')})`;
}

function deriveLayout(colors) {
  // Check if user’s “white” is actually dark
  const darkMode = isDarkColor(colors.white);

  // Body background:
  // If the base "white" is truly dark, let's darken it more to get a deeper “bodyBg”
  // else if it's truly light, we might lighten or darken less.
  const bodyBg = darkMode
    ? darken(colors.white, 0.25) // e.g. #111633 => #0B0D26-ish
    : darken(colors.white, 0.05); // for lighter themes

  // For sidebar background, we can directly use `colors.primaryAlt`, or again,
  // decide on a logic if we want it even darker/lighter, etc.
  const sidebarBg = darkMode
    ? lighten(colors.white, 0.05)
    : darken(colors.white, 0.1);

  return {
    general: {
      bodyBg
    },
    sidebar: {
      background: colors.primaryAlt,
      textColor: darkMode
        ? lighten(colors.secondary, 0.2)
        : darken(colors.secondary, 0.2),
      dividerBg: darkMode ? darken(bodyBg, 0.25) : lighten(bodyBg, 0.25),
      menuItemColor: darkMode
        ? colors.secondary
        : darken(colors.secondary, 0.3),
      menuItemColorActive: '#ffffff',
      menuItemBg: colors.primaryAlt,
      menuItemBgActive: alpha(colors.primaryAlt, 0.6),
      menuItemIconColor: darkMode
        ? '#444A6B' // from your example
        : darken(colors.secondary, 0.4),
      menuItemIconColorActive: '#ffffff',
      menuItemHeadingColor: darken(colors.secondary, 0.3)
    }
  };
}

export function createThemeFromPalette(colors) {
  const isBackgroundDark = isDarkColor(colors.white);
  const derivedBlack = isBackgroundDark
    ? lighten(colors.white, 0.9)
    : darken(colors.white, 0.9);
  const derivedSecondary = isBackgroundDark
    ? lighten(colors.white, 0.7)
    : darken(colors.white, 0.7);
  const derivedPrimaryAlt = isBackgroundDark
    ? lighten(colors.white, 0.05)
    : darken(colors.white, 0.1);
  const contrast = isDarkColor(colors.white) ? '#fff' : '#000';
  const derivedColors = {
    ...colors,
    trueWhite: contrast,
    black: derivedBlack,
    secondary: derivedSecondary,
    primaryAlt: derivedPrimaryAlt
  };
  return {
    colors: derivedColors,
    darkMode: isBackgroundDark,
    //  A) GRADIENTS
    //     Each “blue/orange/purple/green/pink/black/gold” can derive from your
    //     base palette. For instance, "blue" from colors.info,
    //     "orange" from colors.warning, "purple" from colors.primary, etc.
    gradients: {
      blue1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.info, 0.2)
          : darken(derivedColors.info, 0.2),
        isBackgroundDark
          ? darken(derivedColors.info, 0.2)
          : lighten(derivedColors.info, 0.2)
      ),
      blue2: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.info, 0.4)
          : darken(derivedColors.info, 0.4),
        derivedColors.info
      ),
      blue3: createLinearGradient(
        135,
        isBackgroundDark
          ? darken(derivedColors.info, 0.6)
          : lighten(derivedColors.info, 0.6),
        isBackgroundDark
          ? darken(derivedColors.info, 0.8)
          : lighten(derivedColors.info, 0.8)
      ),
      blue4: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.info, 0.25)
          : darken(derivedColors.info, 0.25),
        isBackgroundDark
          ? darken(derivedColors.info, 0.25)
          : lighten(derivedColors.info, 0.25)
      ),
      blue5: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.info, 0.1)
          : darken(derivedColors.info, 0.1),
        isBackgroundDark
          ? darken(derivedColors.info, 0.35)
          : lighten(derivedColors.info, 0.35)
      ),

      orange1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.warning, 0.2)
          : darken(derivedColors.warning, 0.2),
        isBackgroundDark
          ? darken(derivedColors.warning, 0.2)
          : lighten(derivedColors.warning, 0.2)
      ),
      orange2: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.warning, 0.4)
          : darken(derivedColors.warning, 0.4),
        derivedColors.warning
      ),
      orange3: createLinearGradient(
        120,
        isBackgroundDark
          ? lighten(derivedColors.warning, 0.4)
          : darken(derivedColors.warning, 0.4),
        derivedColors.warning
      ),

      purple1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.primary, 0.3)
          : darken(derivedColors.primary, 0.3),
        isBackgroundDark
          ? darken(derivedColors.primary, 0.2)
          : lighten(derivedColors.primary, 0.2)
      ),
      purple3: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.primary, 0.1)
          : darken(derivedColors.primary, 0.1),
        isBackgroundDark
          ? darken(derivedColors.primary, 0.25)
          : lighten(derivedColors.primary, 0.25)
      ),

      pink1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.error, 0.3)
          : darken(derivedColors.error, 0.3),
        isBackgroundDark
          ? darken(derivedColors.error, 0.2)
          : lighten(derivedColors.error, 0.2)
      ),
      pink2: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.error, 0.2)
          : darken(derivedColors.error, 0.2),
        derivedColors.error
      ),

      green1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.success, 0.3)
          : darken(derivedColors.success, 0.3),
        isBackgroundDark
          ? darken(derivedColors.success, 0.2)
          : lighten(derivedColors.success, 0.2)
      ),
      green2: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.success, 0.2)
          : darken(derivedColors.success, 0.2),
        derivedColors.success
      ),

      black1: createLinearGradient(
        100.66,
        isBackgroundDark
          ? lighten(derivedColors.white, 0.2)
          : darken(derivedColors.white, 0.2),
        isBackgroundDark
          ? darken(derivedColors.white, 0.2)
          : lighten(derivedColors.white, 0.2)
      ),
      black2: createLinearGradient(
        60,
        isBackgroundDark
          ? lighten(derivedColors.white, 0.1)
          : darken(derivedColors.white, 0.1),
        isBackgroundDark
          ? lighten(derivedColors.white, 0.25)
          : darken(derivedColors.white, 0.25)
      ),

      gold1: createLinearGradient(
        135,
        isBackgroundDark
          ? lighten(derivedColors.warning, 0.6)
          : darken(derivedColors.warning, 0.6),
        isBackgroundDark
          ? darken(derivedColors.warning, 0.1)
          : lighten(derivedColors.warning, 0.1)
      )
    },

    //  B) SHADOWS
    //     Base your “success/error/info/primary/warning” shadows
    //     on the color palette. For instance, 'success' uses derivedColors.success, etc.
    shadows: {
      success: `0px 1px 4px ${alpha(
        derivedColors.success,
        0.25
      )}, 0px 3px 12px 2px ${alpha(derivedColors.success, 0.35)}`,
      error: `0px 1px 4px ${alpha(
        derivedColors.error,
        0.25
      )}, 0px 3px 12px 2px ${alpha(derivedColors.error, 0.35)}`,
      info: `0px 1px 4px ${alpha(
        derivedColors.info,
        0.25
      )}, 0px 3px 12px 2px ${alpha(derivedColors.info, 0.35)}`,
      primary: `0px 1px 4px ${alpha(
        derivedColors.primary,
        0.25
      )}, 0px 3px 12px 2px ${alpha(derivedColors.primary, 0.35)}`,
      warning: `0px 1px 4px ${alpha(
        derivedColors.warning,
        0.25
      )}, 0px 3px 12px 2px ${alpha(derivedColors.warning, 0.35)}`,

      // Card shadows can be static or also derived from a palette color:
      card: `0px 0px 2px ${alpha(derivedColors.secondary, 1)}`,
      cardSm: `0px 0px 2px ${alpha(derivedColors.secondary, 1)}`,
      cardLg:
        '0 0rem 14rem 0 rgb(255 255 255 / 20%), ' +
        '0 0.8rem 2.3rem rgb(111 130 156 / 3%), ' +
        '0 0.2rem 0.7rem rgb(17 29 57 / 15%)'
    },

    //  C) LAYOUT
    layout: deriveLayout(derivedColors),

    //  D) ALPHA VERSIONS (if you want quick references)
    alpha: {
      white: {
        5: alpha(derivedColors.white, 0.02),
        10: alpha(derivedColors.white, 0.1),
        30: alpha(derivedColors.white, 0.3),
        50: alpha(derivedColors.white, 0.5),
        70: alpha(derivedColors.white, 0.7),
        100: derivedColors.white
      },
      trueWhite: {
        5: alpha(derivedColors.trueWhite, 0.02),
        10: alpha(derivedColors.trueWhite, 0.1),
        30: alpha(derivedColors.trueWhite, 0.3),
        50: alpha(derivedColors.trueWhite, 0.5),
        70: alpha(derivedColors.trueWhite, 0.7),
        100: derivedColors.trueWhite
      },
      black: {
        5: alpha(derivedColors.black, 0.02),
        10: alpha(derivedColors.black, 0.1),
        30: alpha(derivedColors.black, 0.3),
        50: alpha(derivedColors.black, 0.5),
        70: alpha(derivedColors.black, 0.7),
        100: derivedColors.black
      }
    },

    //  E) PALETTE SCALES (lighter, main, dark)
    //     By tying these to your base derivedColors, you keep everything in sync
    secondary: {
      lighter: alpha(derivedColors.secondary, 0.85),
      light: alpha(derivedColors.secondary, 0.6),
      main: derivedColors.secondary,
      dark: darken(derivedColors.secondary, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    },
    primary: {
      lighter: alpha(derivedColors.primary, 0.85),
      light: alpha(derivedColors.primary, 0.3),
      main: derivedColors.primary,
      dark: darken(derivedColors.primary, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    },
    success: {
      lighter: alpha(derivedColors.success, 0.85),
      light: alpha(derivedColors.success, 0.3),
      main: derivedColors.success,
      dark: darken(derivedColors.success, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    },
    warning: {
      lighter: alpha(derivedColors.warning, 0.85),
      light: alpha(derivedColors.warning, 0.3),
      main: derivedColors.warning,
      dark: darken(derivedColors.warning, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    },
    error: {
      lighter: alpha(derivedColors.error, 0.85),
      light: alpha(derivedColors.error, 0.3),
      main: derivedColors.error,
      dark: darken(derivedColors.error, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    },
    info: {
      lighter: alpha(derivedColors.info, 0.85),
      light: alpha(derivedColors.info, 0.3),
      main: derivedColors.info,
      dark: darken(derivedColors.info, 0.2),
      contrast: isBackgroundDark ? '#fff' : '#000'
    }
  };
}
