import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken,
  Typography,
  Link
} from '@mui/material';
import Text from 'src/components/Text';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import { getUser } from 'src/models/user';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: ${theme.zIndex.drawer - 1};
        height: 100%;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { CompetitionPoints } = getUser();

  const sideBarComponent = (drawer: boolean) => {
    return (
      <SidebarWrapper
        sx={
          drawer
            ? {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.header.background
                    : darken(theme.colors.alpha.black[100], 0.5)
              }
            : {
                display: {
                  xs: 'none',
                  lg: 'inline-block'
                },
                position: 'fixed',
                left: 0,
                top: 0,
                background:
                  theme.palette.mode === 'dark'
                    ? theme.header.background
                    : darken(theme.colors.alpha.black[100], 0.5),
                boxShadow:
                  theme.palette.mode === 'dark'
                    ? theme.sidebar.boxShadow
                    : 'none'
              }
        }
      >
        <Scrollbar>
          {/* <Box mt={3}>
            <Box
              mx={5}
              sx={{
                width: 52
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          /> */}
          <SidebarMenu />
        </Scrollbar>
        {/* <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        /> */}
        {/* <Box p={2}>
          <Button
            href="https://bloomui.com"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="success"
            size="small"
            fullWidth
          >
            Buy Premium
          </Button>
        </Box> */}
        {/* <Box p={2} sx={{ backgroundImage: theme.colors.gradients.blue1 }}>
          <Typography variant="h4">
            <Text color="warning">
              Your Entry: {CompetitionPoints || 0}
              <br />
              <Link
                href="https://discord.com/channels/698426461534814270/710735629910147082/1157528929695977522"
                color={'yellow'}
              >
                Earn entries by using website
              </Link>
            </Text>
          </Typography>
        </Box> */}
      </SidebarWrapper>
    );
  };

  return (
    <>
      {sideBarComponent(false)}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        {sideBarComponent(true)}
      </Drawer>
    </>
  );
}

export default Sidebar;
