import React from 'react';
import { Box, Grid, Typography, Link, useTheme } from '@mui/material';

function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.colors.alpha.white[100],
        py: 5
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} textAlign="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 4,
              flexWrap: 'wrap'
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Powered by Tokyo Admin Dashboard
            </Typography>
            <Typography variant="h4">
              © {new Date().getFullYear()} WoWLFG
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            All rights reserved.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 3,
              mt: 3
            }}
          >
            <Link
              href="/tos"
              color="inherit"
              underline="hover"
              variant="subtitle1"
            >
              Terms of Service
            </Link>
            <Link
              href="/privacy_policy"
              color="inherit"
              underline="hover"
              variant="subtitle1"
            >
              Privacy Policy
            </Link>
            <Link
              href="/disclaimers"
              color="inherit"
              underline="hover"
              variant="subtitle1"
            >
              Disclaimers
            </Link>
            <Link
              href="/about_us"
              color="inherit"
              underline="hover"
              variant="subtitle1"
            >
              About Us
            </Link>
            <Link
              href="/contact_us"
              color="inherit"
              underline="hover"
              variant="subtitle1"
            >
              Contact Us
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
