import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuListWrapper from 'src/layouts/Styles/Header/Menu';

const ListWrapper = styled(MenuListWrapper)(
  ({ theme }) => `
        
        .MuiListItem-root {
            
            &.MuiListItem-indicators {
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                      font-size: 1rem;
                      font-weight: 600;
                    }
                }

            }
        }
`
);

interface HeaderMenuProps {
  loggedIn: boolean;
  drawer?: boolean;
}

function HeaderMenu({ loggedIn, drawer }: HeaderMenuProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const smoothScrollTo = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate(`/#${sectionId}`);
    }
  };

  const content = (
    <React.Fragment>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => smoothScrollTo('home')}
      >
        <ListItemText
          primary="Home"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      {loggedIn && (
        <ListItemButton
          classes={{ root: 'MuiListItem-indicators' }}
          component={NavLink}
          to="/dashboards/profile"
        >
          <ListItemText
            primary="Dashboard"
            slotProps={{
              primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
            }}
          />
        </ListItemButton>
      )}
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/about_us')}
      >
        <ListItemText
          primary="About Us"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/guides')}
      >
        <ListItemText
          primary="Guides"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/contact_us')}
      >
        <ListItemText
          primary="Contact"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/tos')}
      >
        <ListItemText
          primary="Terms of Service"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/privacy_policy')}
      >
        <ListItemText
          primary="Privacy Policy"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
      <ListItemButton
        classes={{ root: 'MuiListItem-indicators' }}
        color="inherit"
        onClick={() => navigate('/disclaimer')}
      >
        <ListItemText
          primary="Disclaimers"
          slotProps={{
            primary: { fontSize: '1rem', fontWeight: 600, noWrap: true }
          }}
        />
      </ListItemButton>
    </React.Fragment>
  );

  return (
    <ListWrapper>
      {drawer ? (
        <List>{content}</List>
      ) : (
        <List component={Box} display="flex">
          {content}
        </List>
      )}
    </ListWrapper>
  );
}

export default HeaderMenu;
