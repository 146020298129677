import {
  Box,
  Typography,
  Container,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  LinearProgress,
  linearProgressClasses
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { AppDispatch, RootState } from 'src/app/store';

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

const oauthUrl = `https://discord.com/oauth2/authorize?client_id=823107559854440468&response_type=code&redirect_uri=${process.env.REACT_APP_URL}%2Fdiscord_oauth&integration_type=1&scope=applications.commands+identify`;

export function LoginButton() {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(
    'Connecting to the server'
  );
  const [dotCount, setDotCount] = useState(1);
  const [dialogColor, setDialogColor] = useState('primary');
  const [progress, setProgress] = useState(0);
  const [progressMax, setProgressMax] = useState(100);

  const dispatch: AppDispatch = useDispatch();
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const userID = useSelector((state: RootState) => state.user.user?._id);
  const navigate = useNavigate();
  const oauthWindowRef = useRef(null);
  const isConnectedRef = useRef(isConnected);
  const userIDRef = useRef(userID);

  useEffect(() => {
    isConnectedRef.current = isConnected;
  }, [isConnected]);

  useEffect(() => {
    userIDRef.current = userID;
  }, [userID]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prev) => (prev % 3) + 1);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setDialogMessage((prev) => prev.replace(/\.*$/, '.'.repeat(dotCount)));
    }
  }, [dotCount, isLoading]);

  const handleOAuthMessage = useCallback(
    async (code: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/validate-token?code=${code}`,
          {
            credentials: 'include'
          }
        );
        if (response.status !== 200) {
          dispatch({ type: 'login/disconnect' });
          throw new Error('Login failed');
        }
        dispatch({ type: 'login/check' });
        setIsLoading(true);
        setDialogMessage('Connecting to the server');
        setProgressMax(20);
        setProgress(0);

        const checkConnection = () =>
          new Promise<void>((resolve, reject) => {
            const interval = setInterval(() => {
              setProgress((prev) => prev + 1);
              if (isConnectedRef.current) {
                clearInterval(interval);
                clearTimeout(timeout);
                resolve();
              }
            }, 1000);

            const timeout = setTimeout(() => {
              clearInterval(interval);
              reject(new Error("Couldn't connect to the server"));
            }, 20000);
          });

        await checkConnection();

        setDialogMessage('Initializing user data, please be patient');
        setProgressMax(60);
        setProgress(0);

        await Promise.all([
          dispatch({ type: 'login/firstSequenceExecuted' }),
          dispatch({
            type: 'socket/Message/send',
            payload: { type: 'initialization', silent: true }
          })
        ]);

        const checkUserID = () =>
          new Promise<void>((resolve, reject) => {
            const interval = setInterval(() => {
              setProgress((prev) => prev + 1);
              if (userIDRef.current) {
                clearInterval(interval);
                clearTimeout(timeout);
                resolve();
              }
            }, 1000);

            const timeout = setTimeout(() => {
              clearInterval(interval);
              reject(
                new Error(
                  "Couldn't fetch user data! Try again! If problem persists, contact support through discord!"
                )
              );
            }, 60000);
          });

        await checkUserID();
        setDialogMessage('Initialization complete');
        setDialogColor('success');
        navigate('/dashboards/profile');
      } catch (error) {
        setDialogMessage(error.message);
        setDialogColor('error');
      } finally {
        setIsLoading(false);
        setProgress(100);
      }
    },
    [dispatch, navigate]
  );

  const openOAuthWindow = () => {
    if (isLoading) return;

    setIsLoading(true);
    setOpenDialog(true);
    setDialogColor('primary');
    setDialogMessage('Waiting for authentication window to open');
    setProgressMax(300);
    setProgress(0);
    if (localStorage.getItem('discordCode')) {
      localStorage.removeItem('discordCode');
    }
    oauthWindowRef.current = window.open(
      oauthUrl,
      'OAuth2 Authentication',
      'width=600,height=600'
    );
    setDialogMessage('Waiting for authentication');

    const checkWindowClosed = setInterval(() => {
      setProgress((prev) => prev + 1);
      const code = localStorage.getItem('discordCode');
      if (code) {
        localStorage.removeItem('discordCode');
        handleOAuthMessage(code);
        clearInterval(checkWindowClosed);
      }
    }, 500);

    setTimeout(() => {
      clearInterval(checkWindowClosed);
      setIsLoading(false);
      setDialogMessage("Couldn't connect to the server");
      setDialogColor('error');
    }, 5 * 60 * 1000); // Stop checking after 5 minutes
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={openOAuthWindow}
        loading={isLoading}
        loadingPosition="center"
        sx={{
          mr: 1,
          marginLeft: 2,
          bgcolor: 'darkpurple',
          color: 'white',
          '&:hover': {
            bgcolor: 'darkpurple'
          }
        }}
      >
        Login with Discord
      </LoadingButton>
      <Dialog open={openDialog} disableEscapeKeyDown fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText
            variant="h2"
            color={dialogColor}
            textAlign="center"
            sx={{ mb: 2 }}
          >
            {dialogMessage}
          </DialogContentText>
          {progress > 0 && progress < progressMax && (
            <BorderLinearProgress
              variant="determinate"
              value={(progress / progressMax) * 100}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            disabled={isLoading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Login() {
  return (
    <>
      <Helmet>
        <title>Not logged in</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Typography variant="h2" sx={{ my: 2 }}>
              You are not logged in yet
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{ mb: 4 }}
            >
              Click the button below and login using your Discord account
            </Typography>
            <LoginButton />
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default Login;
