import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator, themeMaker } from './base';
import { StylesProvider } from '@mui/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';

export const ThemeContext = React.createContext(
  (themeName: string): void => {}
);

const ThemeProviderWrapper: React.FC = (props) => {
  const clientTheme = useSelector(
    (state: RootState) => state?.user?.user?.SiteTheme
  );
  const fullTheme = {
    primary: '#8C7CF0',
    secondary: '#9EA4C1',
    success: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#CBCCD2',
    white: '#111633',
    primaryAlt: '#111633',
    trueWhite: '#ffffff',
    ...clientTheme
  };
  const theme = useMemo(() => themeMaker(fullTheme), [fullTheme]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
