import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';
import socetStore, { persistor } from './app/store';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './ErrorBoundry';
import { FriendlistProvider } from './contexts/FriendlistContext';
import { UserActivityProvider } from './contexts/UserActivityContext';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById('root')!); // Updated to use createRoot

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CookiesProvider>
        <HelmetProvider>
          <SidebarProvider>
            <FriendlistProvider>
              <BrowserRouter>
                <Provider store={socetStore}>
                  <PersistGate loading={null} persistor={persistor}>
                    <UserActivityProvider>
                      <App />
                    </UserActivityProvider>
                  </PersistGate>
                </Provider>
              </BrowserRouter>
            </FriendlistProvider>
          </SidebarProvider>
        </HelmetProvider>
      </CookiesProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Keep the service worker setup
serviceWorker.unregister();
