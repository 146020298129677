import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  TypographyProps
} from '@mui/material';
import Text from 'src/components/Text';
import HelpIcon from '@mui/icons-material/Help';

export interface PaymentGracePeriodProps
  extends Omit<TypographyProps, 'color'> {
  GracePeriod: number;
}

export const PaymentGracePeriod = ({
  GracePeriod,
  ...typographyProps
}: PaymentGracePeriodProps) => {
  const gracePeriodString =
    GracePeriod == 0
      ? 'Instant Payment'
      : GracePeriod >= 1
      ? `${GracePeriod.toFixed(0)} Hour${GracePeriod >= 2 ? 's' : ''} Pay Limit`
      : `${(GracePeriod * 60).toFixed(0)} Minute${
          GracePeriod * 60 >= 2 ? 's' : ''
        } Pay Limit`;
  return (
    <Box display={'inline-flex'}>
      <Typography {...typographyProps} fontSize={13}>
        <Text>{gracePeriodString}</Text>
        <Tooltip
          title={
            <Box>
              <Typography variant="body2">
                Poster will be unable to post new runs after this grace period
                after the run is completed.
              </Typography>
            </Box>
          }
          disableInteractive
          placement="top-start"
        >
          <HelpIcon color="primary" sx={{ ml: 0.25, width: 15, height: 15 }} />
        </Tooltip>
      </Typography>
    </Box>
  );
};

export default PaymentGracePeriod;
