import produce from 'immer';
import set from 'just-safe-set';
import unset from 'lodash/unset';
import get from 'lodash/get';

type MongoOperation = {
  $set?: { [key: string]: any };
  $unset?: { [key: string]: any };
  $inc?: { [key: string]: number };
  $push?: { [key: string]: any };
  $pull?: { [key: string]: any };
  $addToSet?: { [key: string]: any };
  $rename?: { [key: string]: string };
};

export const mongoUpdate = produce((draft: any, update: MongoOperation) => {
  if (update.$set) {
    for (const [key, value] of Object.entries(update.$set)) {
      set(draft, key, value);
    }
  }

  if (update.$unset) {
    for (const key of Object.keys(update.$unset)) {
      unset(draft, key);
    }
  }

  if (update.$inc) {
    for (const [key, value] of Object.entries(update.$inc)) {
      const existingValue = get(draft, key, 0);
      set(draft, key, existingValue + value);
    }
  }

  if (update.$push) {
    for (const [key, value] of Object.entries(update.$push)) {
      const arr = get(draft, key, []);
      arr.push(value);
      set(draft, key, arr);
    }
  }

  if (update.$pull) {
    for (const [key, value] of Object.entries(update.$pull)) {
      const arr = get(draft, key, []);
      const filteredArr = arr.filter((item) => !matches(item, value));
      set(draft, key, filteredArr);
    }
  }

  if (update.$addToSet) {
    for (const [key, addToSetValue] of Object.entries(update.$addToSet)) {
      const arr = get(draft, key, []);
      // Check if the value has $each modifier
      if (addToSetValue.$each) {
        addToSetValue.$each.forEach((value) => {
          if (!arr.includes(value)) {
            arr.push(value);
          }
        });
      } else {
        // Handle the case without $each, the original logic
        if (!arr.includes(addToSetValue)) {
          arr.push(addToSetValue);
        }
      }
      set(draft, key, arr);
    }
  }

  if (update.$rename) {
    for (const [oldKey, newKey] of Object.entries(update.$rename)) {
      const value = get(draft, oldKey);
      unset(draft, oldKey);
      set(draft, newKey, value);
    }
  }
});

interface QueryCondition {
  $or?: QueryCondition[];
  $and?: QueryCondition[];
  [key: string]: any; // This allows for other key-value pairs
}

interface ComparisonOperators {
  $eq?: any;
  $ne?: any;
  $gt?: any;
  $lt?: any;
  $gte?: any;
  $lte?: any;
  $in?: any[];
  $nin?: any[];
  $exists?: boolean;
  $not?: QueryCondition | ComparisonOperators; // Allow negation
  // Add additional operators as needed
}

function isComparisonOperator(value: any): value is ComparisonOperators {
  return (
    value &&
    typeof value === 'object' &&
    Object.keys(value).some((key) =>
      [
        '$eq',
        '$ne',
        '$gt',
        '$lt',
        '$gte',
        '$lte',
        '$in',
        '$nin',
        '$exists',
        '$not'
      ].includes(key)
    )
  );
}

function matchCondition(document: any, condition: QueryCondition): boolean {
  if (condition.$or) {
    return condition.$or.some((cond) => matchCondition(document, cond));
  }

  if (condition.$and) {
    return condition.$and.every((cond) => matchCondition(document, cond));
  }

  for (const [key, value] of Object.entries(condition)) {
    const fieldValue = get(document, key);

    if (isComparisonOperator(value)) {
      if (value.$eq !== undefined && fieldValue !== value.$eq) {
        return false;
      }
      if (value.$ne !== undefined && fieldValue === value.$ne) {
        return false;
      }
      if (value.$gt !== undefined && fieldValue <= value.$gt) {
        return false;
      }
      if (value.$lt !== undefined && fieldValue >= value.$lt) {
        return false;
      }
      if (value.$gte !== undefined && fieldValue < value.$gte) {
        return false;
      }
      if (value.$lte !== undefined && fieldValue > value.$lte) {
        return false;
      }
      if (value.$in !== undefined && !value.$in.includes(fieldValue)) {
        return false;
      }
      if (value.$nin !== undefined && value.$nin.includes(fieldValue)) {
        return false;
      }
      if (value.$exists !== undefined) {
        const exists = fieldValue !== undefined;
        if (value.$exists !== exists) {
          return false;
        }
      }
      if (
        value.$not !== undefined &&
        matchCondition({ [key]: fieldValue }, { [key]: value.$not })
      ) {
        return false;
      }
    } else {
      // Direct equality
      if (fieldValue !== value) {
        return false;
      }
    }
  }

  return true;
}

const matches = (item: any, criteria: any): boolean => {
  if (Array.isArray(criteria)) {
    return criteria.some((criterion) => matches(item, criterion));
  } else if (typeof criteria === 'object' && criteria !== null) {
    return matchCondition(item, criteria); // Use matchCondition for objects
  } else {
    return item === criteria; // Direct comparison for primitives
  }
};

export function updateMany(
  collection: any[],
  query: QueryCondition | undefined,
  update: MongoOperation
): any[] {
  if (query === undefined || Object.keys(query).length === 0) {
    return collection.map((document) => mongoUpdate(document, update));
  }
  return collection.map((document) => {
    if (matchCondition(document, query)) {
      return mongoUpdate(document, update);
    } else {
      return document;
    }
  });
}
