import {
  Box,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme
} from '@mui/material';
import React from 'react';
import HelpIcon from '@mui/icons-material/Help';

export interface CutProps extends Omit<TypographyProps, 'color'> {
  KarmaGain: number;
}

export const Karma = ({ KarmaGain, ...typographyProps }: CutProps) => {
  const theme = useTheme();
  const color =
    KarmaGain > 0
      ? theme.palette.success.main
      : KarmaGain < 0
      ? theme.palette.error.main
      : theme.palette.warning.main;
  return (
    <React.Fragment>
      <Box display={'inline-flex'}>
        <Typography variant="body2" {...typographyProps} color={color}>
          {KarmaGain}{' '}
          {KarmaGain > 0
            ? 'Karma Gain'
            : KarmaGain == 0
            ? 'Karma'
            : 'Karma Loss'}
        </Typography>
      </Box>
      <Tooltip
        title={
          <Box>
            <Typography variant="body2">
              The intent of the karma system is to encourage users to
              post/signup runs with higher cuts and discourage them from
              posting/signing up runs with lower cuts. Check under the "Karma"
              tab under "Statistics" for more information.
            </Typography>
          </Box>
        }
        disableInteractive
        placement="top-start"
      >
        <HelpIcon color="primary" sx={{ ml: 0.25, width: 15, height: 15 }} />
      </Tooltip>
    </React.Fragment>
  );
};

export default Karma;
