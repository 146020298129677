import {
  Box,
  Avatar,
  Typography,
  Card,
  styled,
  Divider,
  Dialog,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  useTheme
} from '@mui/material';

import {
  formatDistance,
  format,
  subDays,
  subHours,
  subMinutes
} from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import { DiscordDetails, getBadgeColor, getUser } from 'src/models/user';
import {
  DirectMessage,
  GroupMessage,
  Message,
  SampleGroupMessage
} from 'src/models/message';
import { renderMarkdownContent } from 'src/components/Utility/RenderMarkdown';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePopUp } from 'src/components/WoW/Profile';
import { RootState } from 'src/app/store';

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.light};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(0.5)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 900px;
      display: inline-flex;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(0.5)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 900px;
      display: inline-flex;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
`
);

interface ChatContentProps {
  Messages: Message[];
  MessageType: 'Direct' | 'Group';
  setSelectedMessageAction?: React.Dispatch<
    React.SetStateAction<[Message, string]>
  >;
}

function ChatContent({
  Messages,
  MessageType,
  setSelectedMessageAction
}: ChatContentProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const handleOpen = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpen(true);
  };
  const dispatch = useDispatch();
  const cachedDiscordDetails = useSelector(
    (state: RootState) => state.user?.user?.CachedDiscordDetails
  );
  const userID = useSelector((state: RootState) => state.user?.user?._id);
  const userDiscordDetails = useSelector(
    (state: RootState) => state.user?.user?.DiscordDetails
  );
  const uniqueSenders = new Set(Messages?.map((message) => message.Sender));
  const uniqueSendersArray = Array.from(uniqueSenders);
  const sendersDiscordDetails: { [key: string]: DiscordDetails } =
    Object.fromEntries(
      uniqueSendersArray.map((sender) => [
        sender,
        cachedDiscordDetails?.[sender]
      ])
    );
  const onlineStatuses = useSelector(
    (state: RootState) => state.user?.onlineStatuses
  );
  const sendersOnlineStatus: {
    [key: string]: 'success' | 'secondary' | 'warning';
  } = Object.fromEntries(
    uniqueSendersArray.map((sender) => [
      sender,
      getBadgeColor(onlineStatuses?.[sender])
    ])
  );

  const [openProfile, setOpenProfile] = useState<string>(undefined);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let discordIDs = [];
    for (const [key, value] of Object.entries(sendersDiscordDetails)) {
      if (
        value === undefined ||
        value.LastUpdate < new Date().getTime() / 1000 - 86400
      ) {
        discordIDs.push(key);
      }
    }
    if (discordIDs.length === 0) {
      return;
    }
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'get_bulk_discord_details',
        data: {
          DiscordIDs: discordIDs
        },
        silent: true
      }
    });
  }, [uniqueSenders]);

  let previousDate = null;

  const messageRefs = useRef<Record<string, HTMLElement | null>>({});

  const scrollToMessage = (messageId: string) => {
    const element = messageRefs.current[messageId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMessage, setSelectedMessage] = useState<
    null | DirectMessage | GroupMessage
  >(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    message: Message
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedMessage(message as DirectMessage | GroupMessage);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedMessage(null);
  };

  const handleMessageCopy = () => {
    const formattedContent = selectedMessage.Content.replace(/\\n/g, '\n'); // Converts literal \n to actual line breaks
    navigator.clipboard.writeText(formattedContent);
    handleMenuClose();
  };

  const handleMessageDelete = () => {
    dispatch({
      type: 'socket/Message/send',
      payload: {
        type: 'delete_message',
        data: {
          _id: selectedMessage._id,
          type: MessageType,
          roomID:
            'RoomID' in selectedMessage
              ? selectedMessage.RoomID
              : selectedMessage.Recipient
        }
      }
    });
    setMenuAnchorEl(null);
  };

  const handleActionSelect = (action: string) => {
    setSelectedMessageAction([selectedMessage, action]);
    handleMenuClose();
  };

  return (
    <Box>
      {Messages?.map((message) => {
        if (
          !message ||
          (!message.Content && !message.PictureURL) ||
          (message?.EligibleReaders !== undefined &&
            message?.EligibleReaders?.length > 0 &&
            !message?.EligibleReaders?.includes(userID))
        ) {
          return null;
        }
        const currentDate = format(
          new Date(message.TimeStamp * 1000),
          'MMMM dd yyyy'
        );
        const showDivider = currentDate !== previousDate;
        const selfMessage = message.Sender === userID;
        const MessageCard = selfMessage
          ? CardWrapperPrimary
          : CardWrapperSecondary;
        previousDate = currentDate; // Update the tracking variable
        const repliedMessage = Messages.find(
          (msg) => msg._id === message.ReplyTo
        );

        return (
          <Box
            pb={1}
            pl={3}
            pr={3}
            key={message._id}
            ref={(el) =>
              (messageRefs.current[message._id] = el as HTMLElement | null)
            }
          >
            {showDivider && (
              <DividerWrapper sx={{ mb: 3, mt: 3 }}>
                {currentDate}
              </DividerWrapper>
            )}
            <Box
              justifySelf={selfMessage ? 'flex-end' : 'flex-start'}
              display={'flex'}
              gap={4}
            >
              {repliedMessage && (
                <Box
                  sx={{
                    px: 1,
                    mt: 1,
                    backgroundColor: theme.colors.alpha.black[5],
                    borderRadius: theme.general.borderRadius,
                    maxWidth: 250,
                    height: 25,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.25,
                    cursor: 'pointer'
                  }}
                  onClick={() => scrollToMessage(repliedMessage._id)}
                >
                  <ReplyIcon fontSize="small" />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontStyle: 'italic',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                    fontSize={14}
                  >
                    Reply To: {repliedMessage.Content}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="subtitle1"
                sx={{
                  pt: 1,
                  textAlign: selfMessage ? 'end' : 'start'
                }}
              >
                {sendersDiscordDetails?.[message.Sender]?.Username}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent={selfMessage ? 'flex-end' : 'flex-start'}
            >
              {!selfMessage && (
                <IconButton
                  onClick={() => setOpenProfile(message.Sender)}
                  sx={{ my: -0.9, mx: -0.9 }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    badgeContent={''}
                    color={sendersOnlineStatus?.[message.Sender]}
                  >
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={sendersDiscordDetails?.[message.Sender]?.Username}
                      src={sendersDiscordDetails?.[message.Sender]?.AvatarURL}
                    />
                  </Badge>
                </IconButton>
              )}
              <Box
                display="flex"
                alignItems={selfMessage ? 'flex-end' : 'flex-start'}
                flexDirection="column"
                justifyContent="flex-start"
                ml={selfMessage ? 0 : 2}
                mr={selfMessage ? 2 : 0}
              >
                {message.Content && (
                  <MessageCard
                    onContextMenu={(event) => {
                      event.preventDefault();
                      handleMenuOpen(event, message);
                    }}
                  >
                    {/* Render Message Content */}
                    <Typography>
                      {renderMarkdownContent(message.Content)}
                    </Typography>
                  </MessageCard>
                )}
                {message.PictureURL && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      mt: message.Content ? 1 : 0
                    }}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      handleMenuOpen(event, message);
                    }}
                  >
                    <img
                      src={message.PictureURL}
                      alt="Message Attachment"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '150px',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleOpen(message.PictureURL)}
                    />
                  </Box>
                )}
                <Typography
                  variant="subtitle1"
                  sx={{
                    pt: 0.5,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <ScheduleTwoToneIcon
                    sx={{
                      mr: 0.5
                    }}
                    fontSize="small"
                  />
                  {formatDistance(
                    new Date(message.TimeStamp * 1000),
                    new Date(),
                    {
                      addSuffix: true
                    }
                  )}
                </Typography>
                {message?.EdittedAt && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mt: -0.25,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <EditIcon
                      fontSize="small"
                      sx={{
                        mr: 0.5
                      }}
                    />
                    Edited{' '}
                    {formatDistance(
                      new Date(message.EdittedAt * 1000),
                      new Date(),
                      {
                        addSuffix: true
                      }
                    )}
                  </Typography>
                )}
              </Box>
              {selfMessage && (
                <IconButton
                  onClick={() => setOpenProfile(message.Sender)}
                  sx={{ my: -0.9, mx: -0.9 }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    badgeContent={''}
                    color={sendersOnlineStatus?.[message.Sender]}
                  >
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 50,
                        height: 50
                      }}
                      alt={userDiscordDetails.Username}
                      src={userDiscordDetails.AvatarURL}
                    />
                  </Badge>
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        disablePortal
      >
        {userID == selectedMessage?.Sender && !selectedMessage.System && (
          <MenuItem onClick={() => handleActionSelect('edit')}>Edit</MenuItem>
        )}
        {userID == selectedMessage?.Sender && !selectedMessage.System && (
          <MenuItem onClick={handleMessageDelete}>Delete</MenuItem>
        )}
        <MenuItem onClick={() => handleActionSelect('reply')}>Reply</MenuItem>
        <MenuItem onClick={handleMessageCopy}>Copy Text</MenuItem>
      </Menu>
      <Dialog open={open} onClose={handleClose}>
        <img
          src={currentImage}
          alt="Enlarged Message Attachment"
          style={{ width: '100%', height: 'auto' }}
        />
      </Dialog>
      <ProfilePopUp
        id={openProfile}
        discordDetails={
          openProfile
            ? Object.entries(cachedDiscordDetails).find(
                ([id, user]) => id === openProfile
              )?.[1]
            : undefined
        }
        onClose={() => setOpenProfile(undefined)}
      />
    </Box>
  );
}

export default ChatContent;
