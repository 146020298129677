import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  TypographyProps
} from '@mui/material';
import Text from 'src/components/Text';
import HelpIcon from '@mui/icons-material/Help';

export interface AveragePayTimePeriodProps
  extends Omit<TypographyProps, 'color'> {
  AveragePayTime: number;
}

export const AveragePayTimePeriod = ({
  AveragePayTime,
  ...typographyProps
}: AveragePayTimePeriodProps) => {
  const AveragePayTimeString =
    AveragePayTime == 0
      ? 'No Avg Pay Record'
      : AveragePayTime >= 60
      ? `${(AveragePayTime / 60).toFixed(0)} Hour${
          AveragePayTime / 60 >= 2 ? 's' : ''
        } Avg Pay`
      : `${AveragePayTime.toFixed(0)} Minute${
          AveragePayTime >= 2 ? 's' : ''
        } Avg Pay`;
  const color =
    AveragePayTime == 0
      ? 'error'
      : AveragePayTime >= 60
      ? 'warning'
      : 'success';
  return (
    <Box display={'inline-flex'} mt={-0.5}>
      <Typography {...typographyProps} fontSize={13}>
        <Text color={color}>{AveragePayTimeString}</Text>
      </Typography>
      <Tooltip
        title={
          <Box>
            <Typography variant="body2">
              Average time between uploading payment proof and completing the
              run in the last 30 days.
            </Typography>
          </Box>
        }
        disableInteractive
        placement="top-start"
      >
        <HelpIcon color="primary" sx={{ ml: 0.25, width: 15, height: 15 }} />
      </Tooltip>
    </Box>
  );
};

export default AveragePayTimePeriod;
