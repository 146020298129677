import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { getUser } from 'src/models/user';
import Group from 'src/components/WoW/Group';
import MenuListWrapper from 'src/layouts/Styles/Header/Menu';

function HeaderMenu() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isGroupOpen, setIsGroupOpen] = useState<boolean>(false);
  const user = getUser();
  const limitedAccess =
    !(user?.Characters?.length > 0) &&
    (Object.values(user?.Score)?.every((value) => value < 100) ?? true);
  const groupRef = useRef(null);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleGroupClick = (): void => {
    setIsGroupOpen(!isGroupOpen);
  };

  const handleGroupClose = (): void => {
    setIsGroupOpen(false);
  };

  return (
    <>
      <MenuListWrapper
        sx={{
          display: {
            xs: 'block',
            sm: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <Hidden mdDown>
            <ListItemButton
              classes={{ root: 'MuiListItem-indicators' }}
              component={NavLink}
              to="/dashboards/profile"
            >
              <ListItemText
                primary="Profile"
                slotProps={{ primary: { noWrap: true } }}
              />
            </ListItemButton>
            {!limitedAccess && (
              <ListItemButton
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/management/offers/offers"
              >
                <ListItemText
                  slotProps={{ primary: { noWrap: true } }}
                  primary="Boost"
                />
              </ListItemButton>
            )}
            {!limitedAccess && (
              <ListItemButton
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/management/offers/buy"
              >
                <ListItemText
                  slotProps={{ primary: { noWrap: true } }}
                  primary="Buy Boost"
                />
              </ListItemButton>
            )}
            {!limitedAccess && (
              <ListItemButton
                classes={{ root: 'MuiListItem-indicators' }}
                component={NavLink}
                to="/premium"
              >
                <ListItemText
                  slotProps={{ primary: { noWrap: true } }}
                  primary="Support Us"
                />
              </ListItemButton>
            )}
            {!limitedAccess && (
              <ListItemButton
                classes={{ root: 'MuiListItem-indicators' }}
                ref={ref}
                onClick={handleOpen}
              >
                <ListItemText
                  slotProps={{ primary: { noWrap: true } }}
                  primary={
                    <Box display="flex" alignItems="center">
                      Others
                      <Box display="flex" alignItems="center" pl={0.3}>
                        <ExpandMoreTwoToneIcon fontSize="small" />
                      </Box>
                    </Box>
                  }
                />
              </ListItemButton>
            )}
          </Hidden>
          {/* {!limitedAccess && (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              ref={groupRef}
              onClick={handleGroupClick}
            >
              <ListItemText
                slotProps={{ primary: { noWrap: true } }}
                primary={
                  <Box display="flex" alignItems="center">
                    Manage Team
                    <Box display="flex" alignItems="center" pl={0.3}>
                      <ExpandMoreTwoToneIcon fontSize="small" />
                    </Box>
                  </Box>
                }
              />
            </ListItem>
          )} */}
        </List>
      </MenuListWrapper>
      {limitedAccess && (
        <Typography variant="h4">Link your characters for access</Typography>
      )}
      <Menu anchorEl={ref.current} onClose={handleClose} open={isOpen}>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/premium">
          Premium
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/guides">
          Guides
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/dashboards/chats">
          Chats
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/shop">
          Your Products
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/posterhub">
          Poster Hub
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/settings">
          Settings
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/settings/notifications"
        >
          Notifications
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/reviews">
          Reviews
        </MenuItem>
        <MenuItem sx={{ px: 3 }} component={NavLink} to="/reviews/appeals">
          Appeals
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/dashboards/statistics"
        >
          Statistics
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/offers/leaderboards"
        >
          Leaderboards
        </MenuItem>
        <MenuItem
          sx={{ px: 3 }}
          component={NavLink}
          to="/management/offers/karma"
        >
          Karma
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={groupRef.current}
        onClose={handleGroupClose}
        open={isGroupOpen}
      >
        <Group />
      </Menu>
    </>
  );
}

export default HeaderMenu;
