import { alpha, createTheme, darken } from '@mui/material';
import '@mui/lab/themeAugmentation';

export const darkBlueThemeColors = {
  primary: '#8C7CF0',
  secondary: '#9EA4C1',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#CBCCD2',
  white: '#111633',
  primaryAlt: '#111633',
  trueWhite: '#ffffff'
};

export const darkBlueTheme = {
  gradients: {
    blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
    blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
    blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
    blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
    blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
    orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
    orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
    orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
    purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
    pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
    green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
    green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
    black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
    black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
    gold1: 'linear-gradient(135deg, #655e2d 0%, #635600 100%)'
  },
  shadows: {
    success:
      '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error:
      '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary:
      '0px 1px 4px rgba(112, 99, 192, 0.25), 0px 3px 12px 2px rgba(112, 99, 192, 0.35)',
    warning:
      '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 0px 2px #6A7199',
    cardSm: '0px 0px 2px #6A7199',
    cardLg:
      '0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%)'
  },
  layout: {
    general: {
      bodyBg: '#070C27'
    },
    sidebar: {
      background: darkBlueThemeColors.primaryAlt,
      textColor: darkBlueThemeColors.secondary,
      dividerBg: '#272C48',
      menuItemColor: '#9EA4C1',
      menuItemColorActive: '#ffffff',
      menuItemBg: darkBlueThemeColors.primaryAlt,
      menuItemBgActive: 'rgba(43, 48, 77, .6)',
      menuItemIconColor: '#444A6B',
      menuItemIconColorActive: '#ffffff',
      menuItemHeadingColor: darken(darkBlueThemeColors.secondary, 0.3)
    }
  },
  alpha: {
    white: {
      5: alpha(darkBlueThemeColors.white, 0.02),
      10: alpha(darkBlueThemeColors.white, 0.1),
      30: alpha(darkBlueThemeColors.white, 0.3),
      50: alpha(darkBlueThemeColors.white, 0.5),
      70: alpha(darkBlueThemeColors.white, 0.7),
      100: darkBlueThemeColors.white
    },
    trueWhite: {
      5: alpha(darkBlueThemeColors.trueWhite, 0.02),
      10: alpha(darkBlueThemeColors.trueWhite, 0.1),
      30: alpha(darkBlueThemeColors.trueWhite, 0.3),
      50: alpha(darkBlueThemeColors.trueWhite, 0.5),
      70: alpha(darkBlueThemeColors.trueWhite, 0.7),
      100: darkBlueThemeColors.trueWhite
    },
    black: {
      5: alpha(darkBlueThemeColors.black, 0.02),
      10: alpha(darkBlueThemeColors.black, 0.1),
      30: alpha(darkBlueThemeColors.black, 0.3),
      50: alpha(darkBlueThemeColors.black, 0.5),
      70: alpha(darkBlueThemeColors.black, 0.7),
      100: darkBlueThemeColors.black
    }
  },
  secondary: {
    lighter: alpha(darkBlueThemeColors.secondary, 0.85),
    light: alpha(darkBlueThemeColors.secondary, 0.6),
    main: darkBlueThemeColors.secondary,
    dark: darken(darkBlueThemeColors.secondary, 0.2)
  },
  primary: {
    lighter: alpha(darkBlueThemeColors.primary, 0.85),
    light: alpha(darkBlueThemeColors.primary, 0.3),
    main: darkBlueThemeColors.primary,
    dark: darken(darkBlueThemeColors.primary, 0.2)
  },
  success: {
    lighter: alpha(darkBlueThemeColors.success, 0.85),
    light: alpha(darkBlueThemeColors.success, 0.3),
    main: darkBlueThemeColors.success,
    dark: darken(darkBlueThemeColors.success, 0.2)
  },
  warning: {
    lighter: alpha(darkBlueThemeColors.warning, 0.85),
    light: alpha(darkBlueThemeColors.warning, 0.3),
    main: darkBlueThemeColors.warning,
    dark: darken(darkBlueThemeColors.warning, 0.2)
  },
  error: {
    lighter: alpha(darkBlueThemeColors.error, 0.85),
    light: alpha(darkBlueThemeColors.error, 0.3),
    main: darkBlueThemeColors.error,
    dark: darken(darkBlueThemeColors.error, 0.2)
  },
  info: {
    lighter: alpha(darkBlueThemeColors.info, 0.85),
    light: alpha(darkBlueThemeColors.info, 0.3),
    main: darkBlueThemeColors.info,
    dark: darken(darkBlueThemeColors.info, 0.2)
  }
};
