import React from 'react';

import { alpha, createTheme, darken, lighten, Theme } from '@mui/material';
import { darkBlueThemeColors } from './schemes/NebulaFighterTheme';
import { SiteTheme, ThemeColors } from '../models/theme';
import { createThemeFromPalette } from './ThemeFromPallete';

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
        gold1: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
        card: string;
        cardSm: string;
        cardLg: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };
    general: {
      reactFrameworkColor: React.CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      width: string;
      textColor: React.CSSProperties['color'];
      dividerBg: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemBg: React.CSSProperties['color'];
      menuItemBgActive: React.CSSProperties['color'];
      menuItemIconColor: React.CSSProperties['color'];
      menuItemIconColorActive: React.CSSProperties['color'];
      menuItemHeadingColor: React.CSSProperties['color'];
    };
    header: {
      height: string;
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
        gold1: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };

    general: {
      reactFrameworkColor: React.CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      width: string;
      textColor: React.CSSProperties['color'];
      dividerBg: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemBg: React.CSSProperties['color'];
      menuItemBgActive: React.CSSProperties['color'];
      menuItemIconColor: React.CSSProperties['color'];
      menuItemIconColorActive: React.CSSProperties['color'];
      menuItemHeadingColor: React.CSSProperties['color'];
    };
    header: {
      height: string;
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
    };
  }
}

export function themeMaker(themeColors: ThemeColors): Theme {
  const theme = createThemeFromPalette(themeColors);
  const newTheme = createTheme({
    // direction: i18n.dir(),
    colors: {
      gradients: {
        blue1: theme.gradients.blue1,
        blue2: theme.gradients.blue2,
        blue3: theme.gradients.blue3,
        blue4: theme.gradients.blue4,
        blue5: theme.gradients.blue5,
        orange1: theme.gradients.orange1,
        orange2: theme.gradients.orange2,
        orange3: theme.gradients.orange3,
        purple1: theme.gradients.purple1,
        purple3: theme.gradients.purple3,
        pink1: theme.gradients.pink1,
        pink2: theme.gradients.pink2,
        green1: theme.gradients.green1,
        green2: theme.gradients.green2,
        black1: theme.gradients.black1,
        black2: theme.gradients.black2,
        gold1: theme.gradients.gold1
      },
      shadows: {
        success: theme.shadows.success,
        error: theme.shadows.error,
        primary: theme.shadows.primary,
        info: theme.shadows.info,
        warning: theme.shadows.warning
      },
      alpha: {
        white: {
          5: alpha(theme.colors.white, 0.02),
          10: alpha(theme.colors.white, 0.1),
          30: alpha(theme.colors.white, 0.3),
          50: alpha(theme.colors.white, 0.5),
          70: alpha(theme.colors.white, 0.7),
          100: theme.colors.white
        },
        trueWhite: {
          5: alpha(theme.colors.trueWhite, 0.02),
          10: alpha(theme.colors.trueWhite, 0.1),
          30: alpha(theme.colors.trueWhite, 0.3),
          50: alpha(theme.colors.trueWhite, 0.5),
          70: alpha(theme.colors.trueWhite, 0.7),
          100: theme.colors.trueWhite
        },
        black: {
          5: alpha(theme.colors.black, 0.02),
          10: alpha(theme.colors.black, 0.1),
          30: alpha(theme.colors.black, 0.3),
          50: alpha(theme.colors.black, 0.5),
          70: alpha(theme.colors.black, 0.7),
          100: theme.colors.black
        }
      },
      secondary: {
        lighter: alpha(theme.colors.secondary, 0.1),
        light: alpha(theme.colors.secondary, 0.3),
        main: theme.colors.secondary,
        dark: darken(theme.colors.secondary, 0.2)
      },
      primary: {
        lighter: alpha(theme.colors.primary, 0.1),
        light: alpha(theme.colors.primary, 0.3),
        main: theme.colors.primary,
        dark: darken(theme.colors.primary, 0.2)
      },
      success: {
        lighter: alpha(theme.colors.success, 0.1),
        light: alpha(theme.colors.success, 0.3),
        main: theme.colors.success,
        dark: darken(theme.colors.success, 0.2)
      },
      warning: {
        lighter: alpha(theme.colors.warning, 0.1),
        light: alpha(theme.colors.warning, 0.3),
        main: theme.colors.warning,
        dark: darken(theme.colors.warning, 0.2)
      },
      error: {
        lighter: alpha(theme.colors.error, 0.1),
        light: alpha(theme.colors.error, 0.3),
        main: theme.colors.error,
        dark: darken(theme.colors.error, 0.2)
      },
      info: {
        lighter: alpha(theme.colors.info, 0.1),
        light: alpha(theme.colors.info, 0.3),
        main: theme.colors.info,
        dark: darken(theme.colors.info, 0.2)
      }
    },
    general: {
      reactFrameworkColor: '#00D8FF',
      borderRadiusSm: '6px',
      borderRadius: '10px',
      borderRadiusLg: '12px',
      borderRadiusXl: '16px'
    },
    sidebar: {
      background: theme.layout.sidebar.background,
      textColor: theme.layout.sidebar.textColor,
      dividerBg: theme.layout.sidebar.dividerBg,
      menuItemColor: theme.layout.sidebar.menuItemColor,
      menuItemColorActive: theme.layout.sidebar.menuItemColorActive,
      menuItemBg: theme.layout.sidebar.menuItemBg,
      menuItemBgActive: theme.layout.sidebar.menuItemBgActive,
      menuItemIconColor: theme.layout.sidebar.menuItemIconColor,
      menuItemIconColorActive: theme.layout.sidebar.menuItemIconColorActive,
      menuItemHeadingColor: theme.layout.sidebar.menuItemHeadingColor,
      boxShadow: '1px 0 0 #272C48',
      width: '225px'
    },
    header: {
      height: '80px',
      background: theme.layout.sidebar.background,
      boxShadow: '0px 1px 0px #272C48',
      textColor: theme.secondary.main
    },
    spacing: 9,
    palette: {
      common: {
        black: theme.alpha.black[100],
        white: theme.alpha.white[100]
      },
      mode: 'dark',
      primary: {
        light: theme.primary.light,
        main: theme.primary.main,
        dark: theme.primary.dark,
        contrastText: theme.primary.contrast
      },
      secondary: {
        light: theme.secondary.light,
        main: theme.secondary.main,
        dark: theme.secondary.dark,
        contrastText: theme.secondary.contrast
      },
      error: {
        light: theme.error.light,
        main: theme.error.main,
        dark: theme.error.dark,
        contrastText: theme.error.contrast
      },
      success: {
        light: theme.success.light,
        main: theme.success.main,
        dark: theme.success.dark,
        contrastText: theme.success.contrast
      },
      info: {
        light: theme.info.light,
        main: theme.info.main,
        dark: theme.info.dark,
        contrastText: theme.info.contrast
      },
      warning: {
        light: theme.warning.light,
        main: theme.warning.main,
        dark: theme.warning.dark,
        contrastText: theme.warning.contrast
      },
      text: {
        primary: theme.alpha.black[100],
        secondary: theme.alpha.black[70],
        disabled: theme.alpha.black[50]
      },
      background: {
        paper: theme.alpha.white[100],
        default: theme.layout.general.bodyBg
      },
      action: {
        active: theme.alpha.black[100],
        hover: theme.primary.lighter,
        hoverOpacity: 0.1,
        selected: theme.alpha.black[10],
        selectedOpacity: 0.1,
        disabled: theme.alpha.black[50],
        disabledBackground: theme.alpha.black[5],
        disabledOpacity: 0.38,
        focus: theme.alpha.black[10],
        focusOpacity: 0.05,
        activatedOpacity: 0.12
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1840
      }
    },
    components: {
      // MuiBackdrop: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: alpha(darken('#070C27', 0.5), 0.4),
      //       backdropFilter: 'blur(2px)',

      //       '&.MuiBackdrop-invisible': {
      //         backgroundColor: 'transparent',
      //         backdropFilter: 'blur(2px)'
      //       }
      //     }
      //   }
      // },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            marginLeft: 8,
            marginRight: 8,
            fontWeight: 'bold'
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: theme.darkMode
              ? darken(theme.colors.primaryAlt, 0.5)
              : lighten(theme.colors.primaryAlt, 0.5)
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: {
          'html, body': {
            width: '100%',
            height: '100%',
            scrollBehavior: 'smooth'
          },
          body: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            flex: 1
          },
          '#root': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
          },
          html: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            width: '100%',
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased'
          },
          '.child-popover .MuiPaper-root .MuiList-root': {
            flexDirection: 'column'
          },
          '#nprogress': {
            pointerEvents: 'none'
          },
          '#nprogress .bar': {
            background: theme.primary.lighter
          },
          '#nprogress .spinner-icon': {
            borderTopColor: theme.primary.lighter,
            borderLeftColor: theme.primary.lighter
          },
          '#nprogress .peg': {
            boxShadow:
              '0 0 15px ' +
              theme.primary.lighter +
              ', 0 0 8px' +
              theme.primary.light
          },
          ':root': {
            '--swiper-theme-color': theme.primary.main,
            colorScheme: 'dark'
          },
          code: {
            background: theme.info.lighter,
            color: theme.alpha.black[100],
            borderRadius: 4,
            padding: 4
          },
          '@keyframes ripple': {
            '0%': {
              transform: 'scale(.8)',
              opacity: 1
            },
            '100%': {
              transform: 'scale(2.8)',
              opacity: 0
            }
          },
          '@keyframes float': {
            '0%': {
              transform: 'translate(0%, 0%)'
            },
            '100%': {
              transform: 'translate(3%, 3%)'
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          iconOutlined: {
            color: theme.alpha.black[50]
          },
          icon: {
            top: 'calc(50% - 14px)'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: `1px solid ${theme.alpha.black[30]}`
            }
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&:before, &:after': {
              borderBottom: `1px solid ${theme.alpha.black[30]}`
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
              paddingRight: 6
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.alpha.black[30]
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.alpha.black[50]
            },
            '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.primary.main
            }
          }
        }
      },
      MuiListSubheader: {
        styleOverrides: {
          colorPrimary: {
            fontWeight: 'bold',
            lineHeight: '40px',
            fontSize: 13,
            background: theme.alpha.black[5],
            color: theme.alpha.black[70]
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          action: {
            marginTop: -5,
            marginBottom: -5
          },
          title: {
            fontSize: 15
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            borderRadius: '50px'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          colorSecondary: {
            background: theme.alpha.black[5],
            color: theme.alpha.black[100],

            '&:hover': {
              background: theme.alpha.black[10]
            }
          },
          deleteIcon: {
            color: theme.alpha.black[50],

            '&:hover': {
              color: theme.alpha.black[70]
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',

            '&.Mui-expanded': {
              margin: 0
            },
            '&::before': {
              display: 'none'
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 'bold'
          },
          colorDefault: {
            background: theme.alpha.black[30],
            color: theme.alpha.trueWhite[100]
          }
        }
      },
      MuiAvatarGroup: {
        styleOverrides: {
          root: {
            alignItems: 'center'
          },
          avatar: {
            background: theme.alpha.black[10],
            fontSize: 13,
            color: theme.alpha.black[70],
            fontWeight: 'bold',

            '&:first-of-type': {
              border: 0,
              background: 'transparent'
            }
          }
        }
      },
      MuiListItemAvatar: {
        styleOverrides: {
          alignItemsFlexStart: {
            marginTop: 0
          }
        }
      },
      MuiPaginationItem: {
        styleOverrides: {
          page: {
            fontSize: 13,
            fontWeight: 'bold',
            transition: 'all .2s'
          },
          textPrimary: {
            '&.Mui-selected': {
              boxShadow: theme.shadows.primary
            },
            '&.MuiButtonBase-root:hover': {
              background: theme.alpha.black[5]
            },
            '&.Mui-selected.MuiButtonBase-root:hover': {
              background: theme.primary.main
            }
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            textTransform: 'none',
            paddingLeft: 16,
            paddingRight: 16,

            '.MuiSvgIcon-root': {
              transition: 'all .2s'
            }
          },
          endIcon: {
            marginRight: -8
          },
          containedSecondary: {
            backgroundColor: theme.secondary.main,
            color: theme.alpha.white[100],
            border: '1px solid ' + theme.alpha.black[30]
          },
          outlinedSecondary: {
            backgroundColor: theme.alpha.white[100],

            '&:hover, &.MuiSelected': {
              backgroundColor: theme.alpha.black[5],
              color: theme.alpha.black[100]
            }
          },
          sizeSmall: {
            padding: '6px 16px',
            lineHeight: 1.5
          },
          sizeMedium: {
            padding: '8px 20px'
          },
          sizeLarge: {
            padding: '11px 24px'
          },
          textSizeSmall: {
            padding: '7px 12px'
          },
          textSizeMedium: {
            padding: '9px 16px'
          },
          textSizeLarge: {
            padding: '12px 16px'
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false
        },
        styleOverrides: {
          root: {
            borderRadius: 6
          }
        }
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: theme.primary.main,
            background: theme.alpha.white[100],
            transition: 'all .2s',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: theme.colors.trueWhite,
              background: theme.primary.main
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            padding: 8,

            '& .MuiTouchRipple-root': {
              borderRadius: 8
            }
          },
          sizeSmall: {
            padding: 4
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 0
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '& .MuiTouchRipple-root': {
              opacity: 0.3
            }
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            background: theme.alpha.black[10],
            border: 0,
            height: 1
          },
          vertical: {
            height: 'auto',
            width: 1,

            '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
              height: 'auto'
            },
            '&.MuiDivider-absolute.MuiDivider-fullWidth': {
              height: '100%'
            }
          },
          withChildren: {
            '&:before, &:after': {
              border: 0
            }
          },
          wrapper: {
            background: theme.alpha.white[100],
            fontWeight: 'bold',
            height: 24,
            lineHeight: '24px',
            marginTop: -12,
            color: 'inherit',
            textTransform: 'uppercase'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            padding: 0
          },
          elevation0: {
            boxShadow: 'none'
          },
          elevation: {
            boxShadow: theme.shadows.card
          },
          elevation2: {
            boxShadow: theme.shadows.cardSm
          },
          elevation24: {
            boxShadow: theme.shadows.cardLg
          },
          outlined: {
            boxShadow: theme.shadows.card
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover'
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            height: 6
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
              transform: 'none'
            },
            '& .MuiSlider-valueLabel': {
              borderRadius: 6,
              background: theme.alpha.black[100],
              color: theme.alpha.white[100]
            }
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,

            '& .MuiListItem-button': {
              transition: 'all .2s',

              '& > .MuiSvgIcon-root': {
                minWidth: 34
              },

              '& .MuiTouchRipple-root': {
                opacity: 0.2
              }
            },
            '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
              backgroundColor: theme.alpha.black[10]
            },
            '& .MuiMenuItem-root.MuiButtonBase-root:active': {
              backgroundColor: alpha(theme.primary.lighter, 0.2)
            },
            '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
              opacity: 0.2
            }
          },
          padding: {
            padding: '12px',

            '& .MuiListItem-button': {
              borderRadius: 6,
              margin: '1px 0'
            }
          }
        }
      },
      // MuiTabs: {
      //   styleOverrides: {
      //     root: {
      //       height: 38,
      //       minHeight: 38,
      //       overflow: 'visible'
      //     },
      //     indicator: {
      //       height: 38,
      //       minHeight: 38,
      //       borderRadius: 6,
      //       border: '1px solid ' + theme.primary.dark,
      //       boxShadow: '0px 2px 10px ' + theme.primary.light
      //     },
      //     scrollableX: {
      //       overflow: 'visible !important'
      //     }
      //   }
      // },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: 0,
            height: 38,
            minHeight: 38,
            borderRadius: 6,
            transition: 'color .2s',
            textTransform: 'capitalize',

            '&.MuiButtonBase-root': {
              minWidth: 'auto',
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 4
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              color: theme.alpha.trueWhite[100],
              zIndex: 5
            },
            '&:hover': {
              color: theme.alpha.trueWhite[70]
            }
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            padding: 12
          },
          list: {
            padding: 12,

            '& .MuiMenuItem-root.MuiButtonBase-root': {
              fontSize: 14,
              marginTop: 1,
              marginBottom: 1,
              transition: 'all .2s',
              color: theme.alpha.black[70],

              '& .MuiTouchRipple-root': {
                opacity: 0.2
              },

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: theme.alpha.black[100],
                background: alpha(theme.primary.lighter, 0.2)
              }
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            background: 'transparent',
            transition: 'all .2s',

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: theme.alpha.black[100],
              background: alpha(theme.primary.lighter, 0.2)
            },
            '&.Mui-selected:hover': {
              background: alpha(theme.primary.lighter, 0.2)
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root': {
              color: theme.secondary.main,

              '&:hover, &:active, &.active, &.Mui-selected': {
                color: theme.alpha.black[100],
                background: alpha(theme.primary.lighter, 0.2)
              }
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            margin: 1
          },
          root: {
            '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
              {
                right: 14
              }
          },
          clearIndicator: {
            background: alpha(theme.error.lighter, 0.2),
            color: theme.error.main,
            marginRight: 8,

            '&:hover': {
              background: alpha(theme.error.lighter, 0.3)
            }
          },
          popupIndicator: {
            color: theme.alpha.black[70],

            '&:hover': {
              background: alpha(theme.primary.lighter, 0.2)
            }
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            '& .MuiIconButton-root': {
              padding: 8
            }
          },
          select: {
            '&:focus': {
              backgroundColor: 'transparent'
            }
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '0 !important',
            padding: '0 !important'
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            background: theme.alpha.black[5]
          },
          root: {
            transition: 'background-color .2s',

            '&.MuiTableRow-hover:hover': {
              backgroundColor: alpha(theme.alpha.black[5], 0.05)
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottomColor: theme.alpha.black[10],
            fontSize: 14
          },
          head: {
            textTransform: 'uppercase',
            fontSize: 13,
            fontWeight: 'bold',
            color: theme.alpha.black[70]
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          message: {
            lineHeight: 1.5,
            fontSize: 14
          },
          standardInfo: {
            color: theme.info.main
          },
          action: {
            color: theme.alpha.black[70]
          }
        }
      },
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            margin: 0,
            zIndex: 5,
            position: 'absolute',
            top: '50%',
            marginTop: -6,
            left: -6
          },
          outlined: {
            backgroundColor: theme.alpha.white[100],
            boxShadow: '0 0 0 6px ' + theme.alpha.white[100]
          },
          outlinedPrimary: {
            backgroundColor: theme.alpha.white[100],
            boxShadow: '0 0 0 6px ' + theme.alpha.white[100]
          }
        }
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            position: 'absolute',
            height: '100%',
            top: 0,
            borderRadius: 50,
            backgroundColor: theme.alpha.black[10]
          }
        }
      },
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: 0,
            padding: '8px 0',

            '&:before': {
              display: 'none'
            }
          },
          missingOppositeContent: {
            '&:before': {
              display: 'none'
            }
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: alpha('#333333', 0.85),
            padding: '8px 16px',
            fontSize: 13,
            color: '#FFFFFF'
          },
          arrow: {
            color: alpha('#333333', 0.85)
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 33,
            overflow: 'visible',

            '& .MuiButtonBase-root': {
              position: 'absolute',
              padding: 6,
              transition:
                'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            },
            '& .MuiIconButton-root': {
              borderRadius: 100
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
              opacity: 0.3
            }
          },
          thumb: {
            border: '1px solid ' + theme.alpha.black[30],
            boxShadow:
              '0px 9px 14px ' +
              theme.alpha.black[10] +
              ', 0px 2px 2px ' +
              theme.alpha.black[10]
          },
          track: {
            backgroundColor: theme.alpha.black[5],
            border: '1px solid ' + theme.alpha.black[10],
            boxShadow: 'inset 0px 1px 1px ' + theme.alpha.black[10],
            opacity: 1
          },
          colorPrimary: {
            '& .MuiSwitch-thumb': {
              backgroundColor: theme.alpha.white[100]
            },

            '&.Mui-checked .MuiSwitch-thumb': {
              backgroundColor: theme.primary.main
            }
          }
        }
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            paddingTop: 20,
            paddingBottom: 20,
            background: theme.alpha.black[5]
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.MuiStepIcon-completed': {
              color: theme.success.main
            }
          }
        }
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'div',
            h4: 'div',
            h5: 'div',
            h6: 'div',
            subtitle1: 'div',
            subtitle2: 'div',
            body1: 'div',
            body2: 'div'
          }
        },
        styleOverrides: {
          gutterBottom: {
            marginBottom: 4
          },
          paragraph: {
            fontSize: 17,
            lineHeight: 1.7
          }
        }
      }
    },
    shape: {
      borderRadius: 10
    },
    typography: {
      fontFamily:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      h1: {
        fontWeight: 700,
        fontSize: 35
      },
      h2: {
        fontWeight: 700,
        fontSize: 30
      },
      h3: {
        fontWeight: 700,
        fontSize: 25,
        lineHeight: 1.4,
        color: theme.alpha.black[100]
      },
      h4: {
        fontWeight: 700,
        fontSize: 16
      },
      h5: {
        fontWeight: 700,
        fontSize: 14
      },
      h6: {
        fontSize: 15
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 14
      },
      button: {
        fontWeight: 600
      },
      caption: {
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme.alpha.black[50]
      },
      subtitle1: {
        fontSize: 14,
        color: theme.alpha.black[70]
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 15,
        color: theme.alpha.black[50]
      },
      overline: {
        fontSize: 13,
        fontWeight: 700,
        textTransform: 'uppercase'
      }
    },
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none'
    ]
  });
  return newTheme;
}

const themeMap: { [key: string]: Theme } = {
  darkBlue: themeMaker(darkBlueThemeColors)
};
